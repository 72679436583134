import { customEvent } from './utils';

window.tryRun("dropdown", function () {
	let init = function (element) {
		let persist = element.getAttribute('data-persist') === 'true' || false;
        let parent = element.parentNode;
        if (parent.tagName == "LABEL")
            parent = parent.parentNode;
		let relatedTarget = null;
		let menu = parent.querySelector('.dropdown-menu');

		let menuItems = function () {
			var newSet = [];
			var set = menu.children;
			for (var i = 0; i < set.length; i++) {
				set[i].children.length && (set[i].children[0].tagName === 'A' && newSet.push(set[i].children[0]));
				set[i].tagName === 'A' && newSet.push(set[i]);
			}

			return newSet;
		};

		// preventDefault on empty anchor links
		let preventEmptyAnchor = function (anchor) {
			(anchor.href && anchor.href.slice(-1) === '#' || anchor.parentNode && anchor.parentNode.href && anchor.parentNode.href.slice(-1) === '#');
		};

		let toggleDismiss = function () {
			if (element.open) {
				document.addEventListener('click', dismissHandler, false);
				document.addEventListener('keydown', preventScroll, false);
				document.addEventListener('keyup', keyHandler, false);
			} else {
				document.removeEventListener('click', dismissHandler, false);
				document.removeEventListener('keydown', preventScroll, false);
				document.removeEventListener('keyup', keyHandler, false);
				document.removeEventListener('focus', dismissHandler, true);
			}
		};

		let dismissHandler = function (e) {
			var eventTarget = e.target;
			var hasData = eventTarget && (eventTarget.getAttribute('data-toggle') || eventTarget.parentNode && ('getAttribute' in eventTarget.parentNode) && eventTarget.parentNode.getAttribute('data-toggle'));

			if (e.type === 'focus' && (eventTarget === element || eventTarget === menu || menu.contains(eventTarget))) {
				return;
			}

			// var hasData = eventTarget && ('Dropdown' in eventTarget || 'Dropdown' in eventTarget.parentNode);
			if ((eventTarget === menu || menu.contains(eventTarget)) && (persist || hasData)) {
				return;
			} else {
				relatedTarget = eventTarget === element || element.contains(eventTarget) ? element : null;
				hide();
			}
			preventEmptyAnchor.call(e, eventTarget);
		};

		let clickHandler = function (e) {
			relatedTarget = element;
			show();
			preventEmptyAnchor.call(e, e.target);
		};

		let preventScroll = function (e) {
			var key = e.which || e.keyCode;
			if (key === 38 || key === 40) {
				e.preventDefault();
			}
		};

		let keyHandler = function (e) {
			var key = e.which || e.keyCode;
			var activeItem = document.activeElement;
			var idx = menuItems.indexOf(activeItem);
			var isSameElement = activeItem === element;
			var isInsideMenu = menu.contains(activeItem);
			var isMenuItem = activeItem.parentNode === menu || activeItem.parentNode.parentNode === menu;

			if (isMenuItem) { // navigate up | down
				idx = isSameElement ? 0 : key === 38 ? (idx > 1 ? idx - 1 : 0) : key === 40 ? (idx < menuItems.length - 1 ? idx + 1 : idx) : idx;
				menuItems[idx] && setFocus(menuItems[idx]);
			}

			if ((menuItems.length && isMenuItem || !menuItems[length] && (isInsideMenu || isSameElement) || !isInsideMenu) && element.open && key === 27) {
				toggle();
				relatedTarget = null;
			}
		};

		let show = function () {
			customEvent.call(parent, 'dropdown', 'show');
			menu.classList.add('show');
			parent.classList.add('show');

			element.setAttribute('aria-expanded', true);
			customEvent.call(parent, 'dropdown', 'shown');
			element.open = true;

			element.removeEventListener('click', clickHandler, false);
			setTimeout(function () {
				setFocus(menu.getElementsByTagName('INPUT')[0] || element);
				toggleDismiss();
			}, 1);
		};

		let hide = function () {
			customEvent.call(parent, 'dropdown', 'hide', relatedTarget);
			menu.classList.remove('show');
			parent.classList.remove('show');

			customEvent.call(parent, 'dropdown', 'hidden', relatedTarget);
			element.setAttribute('aria-expanded', false);
			element.open = false;

			toggleDismiss();
			setFocus(element);
			setTimeout(function () {
				element.addEventListener('click', clickHandler, false);
			}, 1);
		};

		let setFocus = function (element) {
			element.focus ? element.focus() : element.setActive();
		};

		// set initial state to closed
		element.open = false;
		let toggle = function () {
			if (parent.classList.contains('show') && element.open) {
				hide();
			} else {
				show();
			}
		};

		element.hide = hide;

		if (!('Dropdown' in element)) {
			!'tabindex' in menu && menu.setAttribute('tabindex', '0');
			element.addEventListener('click', clickHandler, false);
		}
		element.Dropdown = 'Dropdown';
	};

	var elements = document.querySelectorAll('[data-toggle="dropdown"]');
	for (var i = 0; i < elements.length; i++) {
		init(elements[i]);
	}
});