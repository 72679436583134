import { emulateTransitionEnd, customEvent, getTransitionDurationFromElement, supportTransitions } from './utils';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

window.tryRun("modal", function () {
	let Modal = function (element) {
		var modalOverlay = 0;

		// determine modal, triggering element
		var btnCheck = element.getAttribute('data-target') || element.getAttribute('href');
		var checkModal = document.querySelector(btnCheck);
		var modal = element.classList.contains('modal') ? element : checkModal;

		if (element.classList.contains('modal')) {
			element = null;
		}

		// invalidate
		if (!modal) {
			return;
		}

		var keyboard = modal.getAttribute('data-keyboard') === 'false' ? false : true;
		var backdrop = modal.getAttribute('data-backdrop') === 'static' ? 'static' : true;
		backdrop = modal.getAttribute('data-backdrop') === 'false' ? false : backdrop;

		var relatedTarget = null, bodyIsOverflowing, scrollBarWidth, overlay, overlayDelay;

		// find fixed-top / fixed-bottom items
		var fixedTop = [].slice.call(document.documentElement.getElementsByClassName('fixed-top'));
		var fixedBottom = [].slice.call(document.documentElement.getElementsByClassName('fixed-bottom'));
		var fixedItems = fixedTop.concat(fixedBottom);

		let getWindowWidth = function () {
			var htmlRect = document.documentElement.getBoundingClientRect();

			return window.innerWidth || (htmlRect.right - Math.abs(htmlRect.left));
		};

		let setScrollbar = function () {
			var bodyStyle = window.getComputedStyle(document.body);
			var bodyPad = parseInt((bodyStyle.paddingRight), 10);
			var itemPad;

			if (bodyIsOverflowing) {
				document.body.style.paddingRight = (bodyPad + scrollBarWidth) + 'px';
				modal.style.paddingRight = scrollBarWidth + 'px';

				if (fixedItems.length) {
					for (var i = 0; i < fixedItems.length; i++) {
						itemPad = window.getComputedStyle(fixedItems[i]).paddingRight;
						fixedItems[i].style.paddingRight = (parseInt(itemPad) + scrollBarWidth) + 'px';
					}
				}
			}
		};

		let resetScrollbar = function () {
			document.body.style.paddingRight = '';
			modal.style.paddingRight = '';

			if (fixedItems.length) {
				for (var i = 0; i < fixedItems.length; i++) {
					fixedItems[i].style.paddingRight = '';
				}
			}
		};

		let measureScrollbar = function () {
			var widthValue;

			var scrollDiv = document.createElement('div');
			scrollDiv.className = 'modal-scrollbar-measure';

			document.body.appendChild(scrollDiv);
			widthValue = scrollDiv.offsetWidth - scrollDiv.clientWidth;
			document.body.removeChild(scrollDiv);

			return widthValue;
		};

		let checkScrollbar = function () {
			bodyIsOverflowing = document.body.clientWidth < getWindowWidth();
			scrollBarWidth = measureScrollbar();
		};

		let createOverlay = function () {
			modalOverlay = 1;

			var newOverlay = document.createElement('div');

			overlay = document.querySelector('.modal-backdrop');
			if (overlay === null) {
				newOverlay.setAttribute('class', 'modal-backdrop fade');
				overlay = newOverlay;
				document.body.appendChild(overlay);
			}
		};

		let removeOverlay = function () {
			overlay = document.querySelector('.modal-backdrop');
			if (overlay && overlay !== null && typeof overlay === 'object') {
				modalOverlay = 0;
				document.body.removeChild(overlay);
				overlay = null;
			}

			customEvent.call(modal, 'modal', 'hidden');
		};

		let keydownHandlerToggle = function () {
			if (modal.classList.contains('show')) {
				document.addEventListener('keydown', keyHandler, false);
			} else {
				document.removeEventListener('keydown', keyHandler, false);
			}
		};

		let resizeHandlerToggle = function () {
			if (modal.classList.contains('show')) {
				window.addEventListener('resize', update, false);
			} else {
				window.removeEventListener('resize', update, false);
			}
		};

		let dismissHandlerToggle = function () {
			if (modal.classList.contains('show')) {
				modal.addEventListener('click', dismissHandler, false);
			} else {
				modal.removeEventListener('click', dismissHandler, false);
			}
		};

		let triggerShow = function () {
			resizeHandlerToggle();
			dismissHandlerToggle();
			keydownHandlerToggle();
			setFocus(modal);

			customEvent.call(modal, 'modal', 'show', relatedTarget);
		};

		let setFocus = function (element) {
			element.focus ? element.focus() : element.setActive();
		};

		let triggerHide = function () {
			modal.style.display = '';
			element && (setFocus(element));

			(function () {
				if (![].slice.call(document.getElementsByClassName('modal show'))[0]) {
					resetScrollbar();
					document.body.classList.remove('modal-open');

					overlay && overlay.classList.contains('fade') ? (overlay.classList.remove('show'), emulateTransitionEnd(overlay, removeOverlay)) : removeOverlay();

					resizeHandlerToggle();
					dismissHandlerToggle();
					keydownHandlerToggle();
					enablePageScroll(modal);
				}
			}());
		};

		let clickHandler = function (e) {
			var clickTarget = e.target;

			clickTarget = clickTarget.hasAttribute('data-target') || clickTarget.hasAttribute('href') ? clickTarget : clickTarget.parentNode;
			if (clickTarget === element && !modal.classList.contains('show')) {
				modal.modalTrigger = element;
				relatedTarget = element;

				// store url without id to be able to retrieve it
				const info = { additionalInformation: 'Remove id' };
				window.history.pushState(info, document.title, window.location.href);

				show();
			}
		};

		let keyHandler = function (e) {
			if (keyboard && e.which == 27 && modal.classList.contains('show')) {
				hide();
			}
		};

		let dismissHandler = function (e) {
			var clickTarget = e.target;

			if (modal.classList.contains('show') && (clickTarget.parentNode.getAttribute('data-dismiss') === 'modal' || clickTarget.getAttribute('data-dismiss') === 'modal' || (clickTarget === modal && backdrop !== 'static'))) {
				hide();

				// store url without id to be able to retrieve it
				const info = { additionalInformation: 'Remove id' };
				window.history.pushState(info, document.title, window.location.href);

				relatedTarget = null;
			}
		};

		let toggle = function () {
			if (modal.classList.contains('show')) {
				hide();
			} else {
				show();
			}
		};

		let show = function () {
			customEvent.call(modal, 'modal', 'show', relatedTarget);

			// we elegantly hide any opened modal
			var currentOpen = [].slice.call(document.getElementsByClassName('modal show'))[0];

			if (currentOpen && currentOpen !== modal) {
				'modalTrigger' in currentOpen && currentOpen.modalTrigger.Modal.hide();
				'Modal' in currentOpen && currentOpen.Modal.hide();
			}

			if (backdrop) {
				!modalOverlay && createOverlay();
			}

			if (overlay && modalOverlay && !overlay.classList.contains('show')) {
				overlay.offsetWidth; // force reflow to enable trasition
				overlayDelay = getTransitionDurationFromElement(overlay);

				overlay.classList.add('show');
			}

			setTimeout(function () {

				// get url without id
				var url = window.location.href;
				if (url.includes('#')) {
					url = url.split('#')[0];
				}

				// replace with url without id
				const info = { additionalInformation: 'Remove id' };
				window.history.replaceState(info, document.title, url);

				modal.style.display = 'block';

				checkScrollbar();
				setScrollbar();
				disablePageScroll(modal);

				document.body.classList.add('modal-open');
				modal.classList.add('show');
				modal.setAttribute('aria-hidden', false);

				modal.classList.contains('fade') ? emulateTransitionEnd(modal, triggerShow) : triggerShow();
			}, supportTransitions && overlay ? overlayDelay : 0);
		};

		let hide = function () {
			customEvent.call(modal, 'modal', 'hide');

			overlay = document.querySelector('.modal-backdrop');
			overlayDelay = overlay && getTransitionDurationFromElement(overlay);

			modal.classList.remove('show');
			modal.setAttribute('aria-hidden', true);

			setTimeout(function () {
				modal.classList.contains('fade') ? emulateTransitionEnd(modal, triggerHide) : triggerHide();
			}, supportTransitions && overlay ? overlayDelay : 0);
		};

		let update = function () {
			if (modal.classList.contains('show')) {
				checkScrollbar();
				setScrollbar();
			}
		};

		// prevent adding event handlers over and over
		// modal is independent of a triggering element
		if (!!element && !('Modal' in element)) {
			element.addEventListener('click', clickHandler, false);
		}

		if (element) {
			element.Modal = 'Modal';
			modal.modalTrigger = element;
		} else {
			modal.Modal = 'Modal';
		}
	};

	var elements = document.querySelectorAll('[data-toggle="modal"]');
	for (var i = 0; i < elements.length; i++) {
		Modal(elements[i]);
	}
});