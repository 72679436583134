window.addEventListener('load', function (e) {

	window.tryRun("googleAnalytics", function () {
	var retryCounter = 0;
	var init = function () {
		var gtmId = document.getElementById("gtm-id").getAttribute('data-gtm');
		if (typeof window.gtag !== 'undefined') {
			window.gtag("config", gtmId, {
				page_location: window.location,
				send_page_view: true,
			});
		}

		if ((typeof gtag === 'function' && gtag.loaded) || retryCounter++ > 20) {
			var elements = document.querySelectorAll('[data-ga="track"]');
			for (var i = 0; i < elements.length; i++) {
				switch (elements[i].tagName) {
					case 'VIDEO':
						elements[i].onplay = function () {
							sendGoogleAnalytics(this, gtmId);
						};
						break;
					case 'FORM':
						elements[i].addEventListener('form.completed', function () {
							sendGoogleAnalytics(this, gtmId, true);
						}, false);
						break;
					case 'PAGEVIEW':
						// send page view information to ga
						var params = new URL(document.location).searchParams;
						var terms = [];

						// sort in alphabetical order and add to list
						params.sort();
						params.forEach(e => {
							terms.push(e);
						});

						// join querystrings if they exist and send to ga
						if (terms.length > 0) {
							var urlString = "/" + terms.join('-');
							var url = document.location.origin + document.location.pathname + urlString;
							ga('send', 'pageview', url);
						}
						break;
					default:
						elements[i].onclick = function () {
							sendGoogleAnalytics(this, gtmId);
						};
						break;
				}
			}
		} else {
			setTimeout(init, 500);
		}
	};

	var sendGoogleAnalytics = function (element, gtmId, form = false) {
		var category = element.getAttribute('data-category'); //Formulär
		var action = element.getAttribute('data-action'); //Beställning
		var label = element.getAttribute('data-label');  //Huskatalog

		if (form) {
			var office = element.querySelector('input[name$="Office"]');
			if (office) {
				var value = el.options[el.selectedIndex].text;
				label = label + ': ' + value;
			}
		}

		if (typeof window.gtag !== 'undefined') {
			gtag('config', gtmId, {
				'custom_map': { 'dimension<Index>': 'dimension_name' },
				send_page_view: false,
			});
			gtag('event', category, { action: label });
		}

		/*if (typeof window.ga !== 'undefined') {
			if (window.ga && ga.create) {
				ga('send', 'event', category, action, label)
			}
		}*/
	}

	init();
});
})