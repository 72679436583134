//Only intended to be used in ArticleColumns type 1 to match button top position under h2

window.tryRun("match-top", function () {
    'use strict';

    var allSources = {};
    var groupNames = [];

    var recheckTimeout = 0;

    var recheck = function () {
        recheckTimeout = 0;
        groupNames.forEach(function (groupName) {
            var elements = [];
            var top = 0;
            Array.prototype.forEach.call(document.querySelectorAll("[data-matchtop='" + groupName + "']"), function (el) {
                el.style.top = "";
                elements.push(el);
                var sourceId = el.getAttribute("data-matchtop-source");
                var source = document.getElementById(sourceId);
                if (source == null)
                    return;
                var rect = source.getBoundingClientRect();
                if (rect.bottom > top)
                    top = rect.bottom;
            });
            elements.forEach(function (el) {
                var rect = el.getBoundingClientRect();
                el.style.top = (top - rect.top) + "px";
            });
        });
    };

    var init = function () {
        var elements = document.querySelectorAll("[data-matchtop]");
        Array.prototype.forEach.call(elements, function (el) {
            if (!el.hasAttribute("data-matchtop-source"))
                return;
            var sourceId = el.getAttribute("data-matchtop-source");
            var source = document.getElementById(sourceId);
            if (source == null)
                return;
            var groupName = el.getAttribute("data-matchtop");
            if (!groupNames.includes(groupName))
                groupNames.push(groupName);
            if (allSources[sourceId] == null) {
                var obj = {
                    iframe: null,
                    id: sourceId,
                    el: source
                };
                obj.iframe = document.createElement("iframe");
                obj.iframe.style.position = "absolute";
                obj.iframe.style.left = "0";
                obj.iframe.style.top = "0";
                obj.iframe.style.right = "0";
                obj.iframe.style.bottom = "0";
                obj.iframe.style.height = "100%";
                obj.iframe.style.width = "100%";
                obj.iframe.style.border = "0";
                obj.iframe.style.backgroundColor = "transparent";
                obj.iframe.style.pointerEvents = "none";

                source.parentElement.style.position = "relative";
                source.parentElement.insertBefore(obj.iframe, source.parentElement.childNodes[0]);

                obj.iframe.contentWindow.addEventListener("resize", function () {
                    if (recheckTimeout != 0)
                        clearTimeout(recheckTimeout);
                    recheckTimeout = setTimeout(recheck, 100);
                });

                el.style.position = "relative";

                allSources[sourceId] = obj;
            }
        });
    };

    init();

    document.addEventListener("DOMContentLoaded", function () {
        recheck();
    });
});