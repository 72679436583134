import { customEvent } from './utils';
import { Notyf } from 'notyf';

window.tryRun("reclamationform", function () {

    let validateEmail = function (email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    let validateConditional = function (input) {
        var allConditionals = document.querySelectorAll("[data-val-conditional]");
        var valid = false
        for (var i = 0; i < allConditionals.length; i++) {
            if (allConditionals[i].checked) {
                valid = true;
            }
        }
    }

    let validatePhone = function (phone) {
        if (phone == "")
            return true;
        var re = /^[\+]?[(]?[0-9]{3,4}[)]?[-\s\.]?[0-9]{6,8}$/im;
        return re.test(String(phone).toLowerCase());
    };

    let getInputValue = function (element) {
        switch (element.nodeName) {
            case "TEXTAREA":
                return element.value;
                break;
            case "INPUT":
                switch (element.type) {
                    case "text":
                    case "file":
                    case "hidden":
                        return element.value;
                    case "checkbox":
                        return element.checked ? element.value : "";
                }
                break;
            case "SELECT":
                return element.value;
        }
        return "";
    };

    let animateError = function (input, lastHeight = -1, lastTime = Date.now()) {
        window.requestAnimationFrame(function () {
            var time = Date.now();
            var delta = time - lastTime;

            var rect = input.error.getBoundingClientRect();
            var height = rect.bottom - rect.top;
            if (lastHeight == -1)
                lastHeight = height;
            var newHeight = 0;
            if (input.error.classList.contains("visible")) {
                if (height < lastHeight - 1) {
                    input.error.style.maxHeight = "unset";
                    return;
                }
                newHeight = height + (delta * 0.4);
            } else {
                if (lastHeight < 0) {
                    input.error.style.maxHeight = "";
                    return;
                }
                newHeight = height - (delta * 0.4);
            }
            input.error.style.maxHeight = newHeight + "px";

            animateError(input, newHeight, time);
        });
    }

    let showError = function (input, msg, parentNode = null) {
        input.errorInner.innerText = msg;
        if (input.error.classList.contains("visible"))
            return;

        input.error.classList.add("visible");
        animateError(input);

        if (parentNode) {
            if (!parentNode.classList.contains("invalid")) {
                parentNode.classList.add("invalid")
            }
        }
    }

    let hideError = function (input, parentNode = null) {
        if (!input.error.classList.contains("visible"))
            return;

        input.error.classList.remove("visible");
        animateError(input);

        parentNode.classList.remove("invalid")
    }

    let validate = function (input, changes) {
        var valid = true;
        var tested = false;
        var msg = "";
        var listenToParent = false;
        var parentNode = null;
        var ignoreInput = false;

        //check if parent is open
        for (var i = 0; i < input.element.attributes.length; i++) {
            if (input.element.attributes[i].name == "data-val-mandatory") {
                listenToParent = true;
            }
        }

        if (listenToParent) {
            var tempParent = null;
            var maxAttempts = 10;
            var attempts = -1;

            function getParent(el) {
                tempParent = el.parentElement;
                attempts += 1;
                if (attempts <= maxAttempts) {
                    if (!tempParent.classList.contains("group-start-parent")) {
                        getParent(tempParent);
                    } else {
                        parentNode = tempParent;
                    }
                }
            }

            getParent(input.element);
        }

        if (parentNode) {
            var headerContainer = parentNode.children[0];
            var headerContainerInputValue = headerContainer.children[1].checked;

            if (!headerContainerInputValue) {
                ignoreInput = true;
            }
        }

        if (!ignoreInput) {
            if (input.requiredMessage) {
                valid &= getInputValue(input.element) != "";
                tested = true;
                msg = input.requiredMessage;
            }
            if (input.phoneMessage && valid) {
                valid &= validatePhone(getInputValue(input.element));
                tested = true;
                msg = input.phoneMessage;
            }
            if (input.emailMessage && valid) {
                valid &= validateEmail(getInputValue(input.element));
                tested = true;
                msg = input.emailMessage;
            }

            if (input.conditionalMessage && valid) {
                var tempValid = false;
                var allConditionals = document.querySelectorAll("[data-val-conditional]");
                for (var i = 0; i < allConditionals.length; i++) {
                    if (allConditionals[i].checked) {
                        tempValid = true;
                    }
                }
                valid &= tempValid
                tested = true;
                msg = input.conditionalMessage;
            }
        }

        if (valid) {
            if (tested)
                hideError(input, parentNode);
        } else {
            if (!changes) {
                showError(input, msg, parentNode);
            }
        }

        return valid;

    };

    let handleChange = function (input) {
        if (input.dataForElement && input.dataForElements.length > 0) {
            var values = [];
            for (var i = 0; i < input.dataForElements.length; i++) {
                var value = getInputValue(input.dataForElements[i]);
                if (value != "")
                    values.push(value);
            }
            input.dataForElement.value = values.join("\n");
            input.dataForElement.dispatchEvent(new Event('change'));
        }

        if (input.error)
            validate(input, true);
    };

    let handleInput = function (input) {
        input.element.addEventListener("change", function () {
            handleChange(input);
        });
        input.element.addEventListener("keyup", function () {
            handleChange(input);
        });
    };

    let handleForm = function (form) {
        var inputs = [];

        var elements = form.querySelectorAll("[data-types]");
        for (var i = 0, element; element = form[i++];) {
            var input = {
                element: element,
                dataForElement: null,
                dataForElements: [],
                emailMessage: element.hasAttribute("data-val-email") ? element.getAttribute("data-val-email") : null,
                phoneMessage: element.hasAttribute("data-val-phone") ? element.getAttribute("data-val-phone") : null,
                requiredMessage: element.hasAttribute("data-val-required") ? element.getAttribute("data-val-required") : null,
                conditionalMessage: element.hasAttribute("data-val-conditional") ? element.getAttribute("data-val-conditional") : null,
                error: null,
                errorInner: null
            };

            if (input.emailMessage || input.phoneMessage || input.requiredMessage || input.conditionalMessage) {
                input.error = form.querySelectorAll("[data-valmsg-for='" + element.name + "']")[0];
                input.errorInner = document.createElement("div");
                if (!input.error) {
                    input.error = document.createElement("div");
                    input.element.parentElement.insertBefore(input.error, input.element.nextSibling);
                }
                input.error.classList.add("error");
                input.error.appendChild(input.errorInner);
            }

            if (input.element.hasAttribute("data-source")) {
                input.dataForElement = document.getElementById(input.element.getAttribute("data-source"));
                if (input.dataForElement)
                    input.dataForElements = form.querySelectorAll("[data-source='" + input.dataForElement.id + "']");
            }

            if (input.error || input.dataForElements.length > 0) {
                inputs.push(input);
                handleInput(input);
            }
        }

        form.addEventListener("submit", function (e) {
            e.preventDefault();

            var clientKey = form.getAttribute("data-captcha-key");
            var action = form.getAttribute("data-captcha-action");

            if (typeof grecaptcha !== "undefined") {

                grecaptcha.ready(function () {
                    grecaptcha.execute(clientKey, { action: action }).then(function (token) {
                        form.querySelector('input[name$="GoogleCaptchaToken"]').value = token;
                    }).then(function () {
                        var valid = true;
                        for (var i = 0; i < inputs.length; i++)
                            valid &= validate(inputs[i], false);

                        if (!valid) {
                            SendGoogleAnalytics(form);
                            return;
                        }

                        var isJson = form.getAttribute("data-result-json");
                        var buildOnline = form.getAttribute("data-show-build-online");

                        var formData = new FormData(form);
                        var request = new XMLHttpRequest();
                        if (isJson)
                            request.overrideMimeType("application/json");
                        request.open("POST", form.action);
                        //request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

                        request.onreadystatechange = function () {
                            if (this.readyState === XMLHttpRequest.DONE) {
                                var resultMessage = "";
                                var isError = false;

                                if (isJson) {
                                    var jsonObj = JSON.parse(this.response);
                                    isError = jsonObj.error;
                                    if (jsonObj.url != null) {
                                        customEvent.call(form, 'form', 'completed');

                                        document.location = jsonObj.url;
                                    } else {
                                        resultMessage = jsonObj.message;
                                    }
                                } else {
                                    resultMessage = this.response;
                                }

                                if (resultMessage != "") {
                                    if (isError) {
                                        const notyf = new Notyf();

                                        notyf.error({
                                            message: resultMessage,
                                            duration: 5000,
                                            icon: false,
                                            position: {
                                                x: 'center',
                                                y: 'center'
                                            },
                                            ripple: false,
                                            dismissible: true
                                        });
                                        SendGoogleAnalytics(form);

                                        return;
                                    } else {
                                        customEvent.call(form, 'form', 'completed');
                                    }

                                    document.getElementById(form.getAttribute("data-result-id")).innerHTML = resultMessage;
                                    if (form.getAttribute("data-scroll-submit") == "1" && !form.parentElement.classList.contains("modal-body")) {
                                        setTimeout(function () {
                                            var top = form.offsetTop - (window.innerHeight / 2) + (form.offsetHeight / 2);
                                            window.scroll({
                                                top: top > form.offsetTop - 100 ? form.offsetTop - 100 : top,
                                                left: 0,
                                                behavior: 'smooth'
                                            });
                                        }, 100);
                                    }

                                    if (buildOnline)
                                        document.getElementById(form.getAttribute("data-result-id")).parentNode.querySelector('.build-online-url').style.display = 'block';
                                }
                            }
                        };

                        request.send(formData);
                    });
                });
            } else {
                var valid = true;
                for (var i = 0; i < inputs.length; i++)
                    valid &= validate(inputs[i], false);

                if (!valid) {
                    SendGoogleAnalytics(form);
                    return;
                }

                var isJson = form.getAttribute("data-result-json");
                var buildOnline = form.getAttribute("data-show-build-online");

                var formData = new FormData(form);
                var request = new XMLHttpRequest();
                if (isJson)
                    request.overrideMimeType("application/json");
                request.open("POST", form.action);
                //request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

                request.onreadystatechange = function () {
                    if (this.readyState === XMLHttpRequest.DONE) {
                        var resultMessage = "";
                        var isError = false;

                        if (isJson) {
                            var jsonObj = JSON.parse(this.response);
                            isError = jsonObj.error;
                            if (jsonObj.url != null) {
                                customEvent.call(form, 'form', 'completed');

                                document.location = jsonObj.url;
                            } else {
                                resultMessage = jsonObj.message;
                            }
                        } else {
                            resultMessage = this.response;
                        }

                        if (resultMessage != "") {
                            if (isError) {
                                const notyf = new Notyf();

                                notyf.error({
                                    message: resultMessage,
                                    duration: 5000,
                                    icon: false,
                                    position: {
                                        x: 'center',
                                        y: 'center'
                                    },
                                    ripple: false,
                                    dismissible: true
                                });
                                SendGoogleAnalytics(form);

                                return;
                            } else {
                                customEvent.call(form, 'form', 'completed');
                            }

                            document.getElementById(form.getAttribute("data-result-id")).innerHTML = resultMessage;
                            if (form.getAttribute("data-scroll-submit") == "1" && !form.parentElement.classList.contains("modal-body")) {
                                setTimeout(function () {
                                    var top = form.offsetTop - (window.innerHeight / 2) + (form.offsetHeight / 2);
                                    window.scroll({
                                        top: top > form.offsetTop - 100 ? form.offsetTop - 100 : top,
                                        left: 0,
                                        behavior: 'smooth'
                                    });
                                }, 100);
                            }

                            if (buildOnline)
                                document.getElementById(form.getAttribute("data-result-id")).parentNode.querySelector('.build-online-url').style.display = 'block';
                        }
                    }
                };

                request.send(formData);
            }
        });
    };


    let forms = document.querySelectorAll('form[data-val-form="reclamation"]');

    for (var i = 0; i < forms.length; i++) {
        handleForm(forms[i]);
    }
});