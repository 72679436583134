const Sticky = (function () {
	let el;
	let lastScroll = 0;

	return {
		elements: function () {
			return {
				stickies: [...document.querySelectorAll('[data-scroll="sticky"]')]
			};
		},

		init: function () {
			el = this.elements();
			this.load();
		},

		load: function () {
			this.setup();
			this.whenScrolling
			window.addEventListener('scroll', () => this.whenScrolling());
			window.addEventListener('resize', () => this.whenResizing());
		},

		setup: function () {
			el.stickies.forEach((sticky, i) => {
				const heightToTop = sticky.getBoundingClientRect().top + window.pageYOffset;
				const outerHeight = sticky.getBoundingClientRect().height;

				sticky.setAttribute('data-originalPosition', heightToTop);
				sticky.setAttribute('data-originalHeight', outerHeight);
			});
		},

		elementExists: function (el) {
			return typeof (el) != 'undefined' && el != null;
		},

		stickyPosition: function (el) {
			return el.getAttribute('data-originalPosition');
		},

		nextStickyPosition: function (current, next) {
			return next.getAttribute('data-originalPosition') - current.getAttribute('data-originalHeight');
		},

		scrollingPositionTop: function (el) {
			return el.getBoundingClientRect().top + window.pageYOffset;
		},

		offsetTop: function (el) {
			return el.getBoundingClientRect().top;
		},

		scrollingPositionBottom: function (el) {
			return el.getBoundingClientRect().bottom + window.pageYOffset;
		},

		headerPosition: function () {
			return window.pageYOffset;
		},

		bottomSectionHit: function (contentElement, sticky) {
			const contentSection = document.getElementById(contentElement);
			const sectionBottom = contentSection.getBoundingClientRect().bottom + window.pageYOffset;
			const stickyPositionScrolling = sticky.getBoundingClientRect().bottom + window.pageYOffset;

			return stickyPositionScrolling >= sectionBottom;
		},

		whenScrolling: function () {
			const body = document.body;
			const scrollUp = "scroll-up";
			const scrollDown = "scroll-down";
			const currentScroll = window.pageYOffset;

			if (currentScroll <= 0) {
				body.classList.remove(scrollUp);
				return;
			}

			el.stickies.forEach((sticky, i) => {
				const nextSticky = el.stickies[i + 1];

				if (this.stickyPosition(sticky) < this.headerPosition()) {
					if (this.elementExists(nextSticky)) {
						if (this.scrollingPositionTop(sticky) > this.nextStickyPosition(sticky, nextSticky) + 140 && !body.classList.contains(scrollDown)) {
							if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
								body.classList.remove(scrollUp);
								body.classList.add(scrollDown);
							}
						} else {
							if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
								body.classList.remove(scrollDown);
								body.classList.add(scrollUp);
							}
						}
						lastScroll = currentScroll;
					}
				}
			});
		},

		whenResizing: function () {
			el.stickies.forEach((sticky, i) => {
				const outerHeight = sticky.offsetHeight;

				sticky.setAttribute('data-originalHeight', outerHeight);
			});
		}
	}
}());

window.tryRun("sticky", function () {
	Sticky.init();
});