import { emulateTransitionEnd, getClosest } from './utils';

window.tryRun("Collapse", function () {
	var init = function (element) {
		// event targets and constants
		var accordion = null;
		var collapse = null;
		var accordionData = element.getAttribute('data-parent');
		var activeCollapse;
		var activeElement;

		let openAction = function (collapseElement, toggle) {
			toggle.classList.add('active');
			if (toggle.hasAttribute('data-close-text')) {
				toggle.innerHTML = toggle.getAttribute('data-close-text');
			}

			collapseElement.isAnimating = true;
			collapseElement.classList.add('collapsing');
			collapseElement.classList.remove('collapse');
			collapseElement.style.height = collapseElement.scrollHeight + 'px';

			emulateTransitionEnd(collapseElement, function () {
				collapseElement.isAnimating = false;
				collapseElement.setAttribute('aria-expanded', 'true');

				toggle.setAttribute('aria-expanded', 'true');
				collapseElement.classList.remove('collapsing');
				collapseElement.classList.add('collapse');
				collapseElement.classList.add('show');
				collapseElement.style.height = '';
			});
		};

		let closeAction = function (collapseElement, toggle) {
			toggle.classList.remove('active');
			if (toggle.hasAttribute('data-open-text')) {
				toggle.innerHTML = toggle.getAttribute('data-open-text');
			}

			collapseElement.isAnimating = true;
			collapseElement.style.height = collapseElement.scrollHeight + 'px'; // set height first
			collapseElement.classList.remove('collapse');
			collapseElement.classList.remove('show');
			collapseElement.classList.add('collapsing');
			collapseElement.offsetWidth; // force reflow to enable transition
			collapseElement.style.height = '0px';

			emulateTransitionEnd(collapseElement, function () {
				collapseElement.isAnimating = false;
				collapseElement.setAttribute('aria-expanded', 'false');
				toggle.setAttribute('aria-expanded', 'false');
				collapseElement.classList.remove('collapsing');
				collapseElement.classList.add('collapse');
				collapseElement.style.height = '';
			});
		};

		let getTarget = function () {
			var href = element.href && element.getAttribute('href');
			var parent = element.getAttribute('data-target');
			var id = href || (parent && parent.charAt(0) === '#') && parent;

			return id && document.querySelector(id);
		};

		let toggle = function (e) {
			e.preventDefault();
			if (!collapse.classList.contains('show')) {
				show();
			} else {
				hide();
			}
		};

		let hide = function () {
			if (collapse.isAnimating) return;

			closeAction(collapse, element);
			element.classList.add('collapsed');
		};

		let show = function () {
			if (accordion) {
				activeCollapse = accordion.querySelector('.collapse.show');
				// activeElement = activeCollapse && (accordion.querySelector('[data-toggle="collapse"][data-target="#' + activeCollapse.id + '"]') || accordion.querySelector('[data-toggle="collapse"][href="#' + activeCollapse.id + '"]'));
				activeElement = activeCollapse && (accordion.querySelector('[data-target="#' + activeCollapse.id + '"]') || accordion.querySelector('[href="#' + activeCollapse.id + '"]'));
			}

			if (!collapse.isAnimating || activeCollapse && !activeCollapse.isAnimating) {
				if (activeElement && activeCollapse !== collapse) {
					closeAction(activeCollapse, activeElement);
					activeElement.classList.add('collapsed');
				}
				openAction(collapse, element);

				element.classList.remove('collapsed');
			}
		};

		if (!('Collapse' in element)) { // prevent adding event handlers twice
			element.addEventListener('click', toggle, false);
		}

		collapse = getTarget();
		collapse.isAnimating = false;
		accordion = accordionData && getClosest(element, accordionData);

		element.Collapse = 'Collapse';
	};

	var elements = document.querySelectorAll('[data-toggle="collapse"]');
	for (var i = 0; i < elements.length; i++) {

		init(elements[i]);
	}
});