export default element => {
    var flyout = document.getElementById('chat-bubble__flyout');
    var closeFlyoutBtn = document.querySelector('.chat-bubble__flyout-close');
    var notificationBar = document.querySelector('.notice-message');
    var selector = document.querySelector('#chatbubble-selector');
    var offices = document.querySelectorAll('[data-office-id]');
    var form = document.getElementById('chat-bubble-form');
    var formSelect = form.getElementsByTagName('select')[0];
    var openFlyoutBtn = document.getElementById('chat-bubble');
    const body = document.querySelector('body');
    const header = document.getElementById('header');
    const scrollspy = document.getElementById('scrollspy');
    const elementSibling = element.nextElementSibling;
    const contacts = document.querySelectorAll('.chat-bubble__content-item-contact');

    let init = function () {
        let scrolled = 0;
        let width = window.innerWidth;
        let session = sessionStorage.getItem('flyout-form');

        setTimeout(() => {
            flyout.style.height = `calc(100vh - ${width < 1024 ? '0' : header.clientHeight + (notificationBar ? notificationBar.clientHeight : 0)}px)`;
        }, 500);
        
        window.addEventListener('scroll', (e) => {
            scrolled = window.pageYOffset;
            width = window.innerWidth;
            const hasScrollDown = body.classList.contains('scroll-down');
            const extraHeight = (scrolled > 0 ? 0 : notificationBar ? notificationBar.clientHeight : 0) + header.clientHeight;

            if (width < 1024) {
                flyout.style.height = `100vh`;
            } else {
                flyout.style.height = `calc(100vh - ${hasScrollDown ? (scrollspy ? scrollspy.clientHeight : 0) : extraHeight}px)`;
            }

        });

        window.addEventListener('resize', (e) => {
            width = e.target.innerWidth;
            const hasScrollDown = body.classList.contains('scroll-down');
            const extraHeight = (scrolled > 0 ? 0 : notificationBar ? notificationBar.clientHeight : 0) + header.clientHeight;
            if (width < 1024) {
                flyout.style.height = `100vh`;

            } else {
                flyout.style.height = `calc(100vh - ${hasScrollDown ? (scrollspy ? scrollspy.clientHeight : 0) : extraHeight}px)`;
            }
        });
            if (form) {

                form.addEventListener('keyup', () => {
                    setSessionStorage();
                });

                if (formSelect) {
                    formSelect.addEventListener('change', (e) => {
                        var officeName = e.target.value;
                        var newOffice = Array.from(document.getElementsByClassName('chat-bubble__content-item')).find(x => {
                            var municipalities = x.getAttribute('data-office-municipalities');

                            if (municipalities) {
                                var splitMunicipalities = municipalities.split('|');

                                const matchedMunicipaity = splitMunicipalities.find(m => m.trim().toLowerCase() == officeName.toLowerCase());
                                return matchedMunicipaity != null
                            }
                        });
                        var selectedOffice = Array.from(offices).filter(of => {
                            return !of.classList.contains('hide');
                        });

                        selectedOffice.forEach(of => of.classList.add('hide'));

                        if (newOffice) {
                            newOffice.classList.remove('hide');
                            const newOfficeImg = newOffice.getElementsByTagName('img')[0];

                            if (newOfficeImg) {
                                openFlyoutBtn.style.backgroundImage = `url('${newOfficeImg.src}')`;
                            }
                        } else {
                            const defaultOffice = Array.from(offices).find(o => { return (o.getAttribute('data-office-id') == 'default') });

                            if (defaultOffice) {
                                defaultOffice.classList.remove('hide');
                                var defaultTitle = defaultOffice.getElementsByTagName('h2');
                                var defaultDescription = defaultOffice.getElementsByTagName('span');
                                var defaultSelect = defaultOffice.getElementsByTagName('select');
                                const defaultImg = defaultOffice.getElementsByTagName('img');
                                const defaultContent = defaultOffice.getElementsByClassName('chat-bubble__content-item-contact')[0];
                            

                                if (defaultImg.length > 0) {
                                    openFlyoutBtn.style.backgroundImage = `url('${defaultImg[0].src}')`;
                                    defaultImg[0].classList.add('hide');
                                }

                                if (defaultTitle.length > 0) {
                                    defaultTitle[0].classList.add('hide');
                                }

                                if (defaultDescription.length > 0) {
                                    defaultDescription[0].classList.add('hide');
                                }

                                if (defaultSelect.length > 0) {
                                    defaultSelect[0].classList.add('hide');
                                }

                                if (defaultContent) {
                                    defaultContent.classList.remove('hide');
                                }
                            }

                        }
                        setSessionStorage();
                    });
                }
            }

        element.addEventListener('click', () => {
            toggleInView();
        });

        if (elementSibling) {
            elementSibling.addEventListener('click', () => {
                toggleInView();
            });
        }
        closeFlyoutBtn.addEventListener('click', () => {
            closeFlyout();
        });

        if (contacts) {
            contacts.forEach(contact => {
                contact.addEventListener('click', () => {
                    if (width < 1024) {
                        if (form) {
                            form.scrollIntoView({ behavior: 'smooth' })
                        }
                    }
                });
            });
        }
        if (selector) {
            selector.addEventListener('change', (e) => {
                var officeName = e.target.value;

                var selectedOffice = Array.from(offices).filter(of => {
                    return !of.classList.contains('hide');
                });

                selectedOffice.forEach(of => of.classList.add('hide'));

                var newOffice = Array.from(document.getElementsByClassName('chat-bubble__content-item')).find(x => {
                    var municipalities = x.getAttribute('data-office-municipalities');

                    if (municipalities) {
                        var splitMunicipalities = municipalities.split('|');

                        const matchedMunicipaity = splitMunicipalities.find(m => m.trim().toLowerCase() == officeName.toLowerCase());
                        return matchedMunicipaity != null;
                    }
                });
                if (newOffice) {
                    newOffice.classList.remove('hide');
                    form.classList.remove('hide');

                    formSelect.value = officeName;
                    const newOfficeImg = newOffice.getElementsByTagName('img')[0];

                    if (newOfficeImg) {
                        openFlyoutBtn.style.backgroundImage = `url('${newOfficeImg.src}')`;
                    }

                }

                if (newOffice.value == 'default') {
                    form.classList.add('hide');
                }

                setSessionStorage();
            });
        }

        session = JSON.parse(session);
        if (session && session['chatbubble-selector']) {
            var selectedOffice = Array.from(offices).filter(of => {
                return !of.classList.contains('hide');
            });


            var newOffice = Array.from(document.getElementsByClassName('chat-bubble__content-item')).find(x => {
                var municipalities = x.getAttribute('data-office-municipalities');
                if (municipalities) {
                    var splitMunicipalities = municipalities.split('|');

                    const matchedMunicipaity = splitMunicipalities.find(m => m.trim().toLowerCase() == session['chatbubble-selector'].toLowerCase());
                    return matchedMunicipaity != null;
                }
            });

            if (newOffice) {
                selectedOffice.forEach(of => of.classList.add('hide'));
                newOffice.classList.remove('hide');
                form.classList.remove('hide');
                const newOfficeImg = newOffice.getElementsByTagName('img')[0];

                if (newOfficeImg) {
                    openFlyoutBtn.style.backgroundImage = `url('${newOfficeImg.src}')`;
                }
            }

        }
    };

    function closeFlyout() {
        if (flyout) {
            if (flyout.classList.contains('inview')) {
                flyout.classList.remove('inview')
            }
        }
    }
    function toggleInView() {
        if (flyout) {
            if (flyout.classList.contains('inview')) {
                flyout.classList.remove('inview')
            }
            if (!flyout.classList.contains('inview')) {
                flyout.classList.add('inview')
            }
        }
    }

    function getSessionStorage() {
        let session = sessionStorage.getItem('flyout-form');

        if (form && session) {
            session = JSON.parse(session);
            const formSelector = form.getElementsByTagName('select')[0];
            const inputs = form.getElementsByTagName('input');
            const textArea = form.getElementsByTagName('textarea')[0];

            if (formSelector) {
                formSelector.value = session['00N7Q00000KbcVe'];
            }

            if (textArea) {
                textArea.value = session['description'];
            }

            if (inputs.length > 0) {
                Array.from(inputs).forEach(input => {
                    if (input.id == 'first_name')
                        input.value = session['first_name'];

                    if (input.id == 'last_name')
                        input.value = session['last_name'];

                    if (input.id == 'phone')
                        input.value = session['phone'];

                    if (input.id == 'email')
                        input.value = session['email'];
                });
            }

            selector.value = session['00N7Q00000KbcVe'];
        }
    }

    function setSessionStorage() {
        if (form) {
            const formSelector = form.getElementsByTagName('select')[0];
            const inputs = form.getElementsByTagName('input');
            const textArea = form.getElementsByTagName('textarea')[0];
            const sessionObject = {}

            sessionObject['00N7Q00000KbcVe'] = formSelector.value;
            sessionObject['chatbubble-selector'] = formSelector.value;

            Array.from(inputs).forEach(input => {
                if (input.id == 'first_name')
                    sessionObject['first_name'] = input.value;

                if (input.id == 'last_name')
                    sessionObject['last_name'] = input.value;

                if (input.id == 'phone')
                    sessionObject['phone'] = input.value;

                if (input.id == 'email')
                    sessionObject['email'] = input.value;

            });

            sessionObject['description'] = textArea.value;

            sessionStorage.setItem('flyout-form', JSON.stringify(sessionObject));

        }
    }
    getSessionStorage();
    init();
}