window.tryRun("match-width", function () {
	'use strict';

	function throttle(fn, threshhold) {
		var last = void 0, deferTimer = void 0;

		return function () {
			var now = Date.now();

			if (last && now < last + threshhold) {
				clearTimeout(deferTimer);
				deferTimer = setTimeout(function () {
					last = now;
					fn();
				}, threshhold);
			} else {
				last = now;
				fn();
			}
		};
	}

	var errorThreshold = 1; // in px
	var initialized = false;
	var allElements = {};
	var allMinWidth = {};
	//var allElementsStyle = {};
	//var remains = void 0;

	var MatchWidth = {
		init: function init() {
			initialized = true;
			var initElements = document.querySelectorAll('[data-match-width]');

			for (var i = 0; i < initElements.length; i++) {
				var className = initElements[i].getAttribute("data-match-width");

				if (allElements[className] == null) {
					allElements[className] = [];
					allMinWidth[className] = 0;
				}
				
				allElements[className].push(initElements[i]);
			}
			MatchWidth.update();
		},
		update: function update(force = false) {
			if (!initialized) {
				MatchWidth.init();

				return;
			}

			process(force);
		},
		check: function check(el) {
			var initElements = el.querySelectorAll('[data-match-width]');

			for (var i = 0; i < initElements.length; i++) {
				var className = initElements[i].getAttribute("data-match-width");

				if (allElements[className] == null) {
					allElements[className] = [];
					allMinWidth[className] = 0;
				}

				allElements[className].push(initElements[i]);
			}
		},
		remove: function remove() {
			if (!initialized)
				return;

			for (var key of Object.keys(allElements)) {
				allElements[key].forEach(function(el) {
					el.style.minWidth = "";
					el.visible = undefined;
				});
				allMinWidth[key] = 0;
			}

			initialized = false;
		}
	};

	function process(force = false) {

		if (force) {
			for (var key of Object.keys(allElements)) {
				allElements[key].forEach(function(el) {
					el.style.minWidth = "";
					el.visible = undefined;
				});
				allMinWidth[key] = 0;
			}
		}

		for (var key of Object.keys(allElements)) {
			var minWidth = allMinWidth[key];
			var padding = 0;
			var style = null;

			allElements[key].forEach(function(el) {
				if (style == null) {
					style = window.getComputedStyle(el);
					padding = parseFloat(style.getPropertyValue('padding-left'), 10) + parseFloat(style.getPropertyValue('padding-right'), 10);
				}

				if (el.visible !== true) {
					var width = el.clientWidth;//rect.right - rect.left;
					if (width > 0) {
						if (width + padding > minWidth)
							minWidth = width + padding;
						el.visible = true;
					} else {
						el.visible = false;
					}
				}
			});

			allMinWidth[key] = minWidth;
		}

		for (var key of Object.keys(allElements)) {
			var minWidth = allMinWidth[key];

			allElements[key].forEach(function(el) {
				if (!el.visible)
					return;
				el.style.minWidth = minWidth + "px";
			});
		}
	};

	var throttledUpdate = throttle(function() { MatchWidth.update(true); }, 200);

	MatchWidth.init();
	window.addEventListener('resize', throttledUpdate);

	document.matchwidth = {
		init: function () {
			MatchWidth.init();
		},	
		check: function (el) {
			MatchWidth.check(el);
		},
		update: function (force = false) {
			MatchWidth.update(force);
		},
		remove: function () {
			MatchWidth.remove();
		}
	}
});