document.addEventListener("DOMContentLoaded", function () {

    //<img border="0" alt="@(Model.Image?.Alt)" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAABmJLR0QAtgDOANEMIR/DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4wYNCjQh2/qIjgAAAA1JREFUCNdj2HbuIgMABukCVk0Ti9cAAAAASUVORK5CYII=" title="@(Model.Image?.Title)" style="width: 100%; height: 100%;">
    var imgUrl = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAABmJLR0QAtgDOANEMIR/DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4wYNCjQh2/qIjgAAAA1JREFUCNdj2HbuIgMABukCVk0Ti9cAAAAASUVORK5CYII=";

    var elements = [];

    var resizeTimer = 0;

    var updateSize = function (data) {
        var rect = data.element.getBoundingClientRect();
        data.img.style.left = "0px";
        data.img.style.top = "0px";
        data.img.style.width = (rect.right - rect.left) + "px";
        data.img.style.height = (rect.bottom - rect.top) + "px";
    };

    var updateAll = function () {
        for (var i = 0; i < elements.length; i++)
            updateSize(elements[i]);
    };

    var handle = function (element) {
        var seoTitle = element.hasAttribute("data-seoimg-title") ? element.getAttribute("data-seoimg-title") : "";
        var seoAlt = element.hasAttribute("data-seoimg-alt") ? element.getAttribute("data-seoimg-alt") : "";
        var originalImage = element.hasAttribute("data-seoimg-url") ? element.getAttribute("data-seoimg-url") : ""


		var img;
		if (originalImage != "" && originalImage.indexOf("/imagecache/7AE2DE11-3750-488E-B454-C21534BB619E") != 0) { //7AE2DE11-3750-488E-B454-C21534BB619E is id of placeholder
            img = document.createElement("img")

            element.appendChild(img);
            img.src = originalImage;
            img.style.display = 'none';
            img.style.width = '0px';
            img.style.height = '0px';
        }

        if (seoTitle == "" && seoAlt == "")
            return;

        if (img) {
            img.setAttribute("title", seoTitle);
            img.setAttribute("alt", seoAlt);
        }

        var data = {
            element: element,
            img: document.createElement("img")
        };

        element.appendChild(data.img);
        data.img.src = imgUrl;
        data.img.setAttribute("border", "0");
        data.img.setAttribute("title", seoTitle);
        data.img.setAttribute("alt", seoAlt);
        data.img.style.position="relative";

        element.addEventListener("transitionend", function () { updateSize(data); });

        updateSize(data);

        elements.push(data);
    };

    var init = function () {
        var elements = document.querySelectorAll("[data-seoimg-title], [data-seoimg-alt]");
        for (var i = 0; i < elements.length; i++)
            handle(elements[i]);
    };

    init();

    window.addEventListener("resize", function () {
        if (resizeTimer != 0)
            clearTimeout(resizeTimer);
        
        resizeTimer = setTimeout(function () {
            resizeTimer = 0;
            updateAll();
        }, 500);
    });
});