import { enableScroll, disableScroll } from './utils';

window.tryRun("scrolltotop", function () {
	let element;

	let trackScroll = function () {
		var scrolled = window.pageYOffset;
		var coords = 50;

		if (scrolled > coords && !element.IsVisible) {
			fadeIn(element);
		}

		if (scrolled < coords && element.IsVisible) {
			fadeOut(element);
		}
	}

	let fadeOut = function (el) {
		el.style.opacity = 1;
		el.IsVisible = false;

		(function fade() {
			if ((el.style.opacity -= .1) < 0) {
				el.style.display = "none";
			} else {
				requestAnimationFrame(fade);
			}
		})();
	};

	let fadeIn = function (el, display) {
		el.style.opacity = 0;
		el.style.display = display || "block";
		el.IsVisible = true;

		(function fade() {
			var val = parseFloat(el.style.opacity);
			if (!((val += .1) > 1)) {
				el.style.opacity = val;
				requestAnimationFrame(fade);
			}
		})();
	};

	let backToTop = function () {
		disableScroll();
		scrollTo(0, 1000);
	}

	let scrollTo = function (to, duration) {
		var doc = document.scrollingElement || document.documentElement;
		var start = doc.scrollTop;
		var change = to - start;
		var startDate = +new Date();

		var easeInOutQuad = function (time, start, change, duration) {
			time /= duration / 2;
			if (time < 1)
				return change / 2 * time * time + start;

			time--;

			return -change / 2 * (time * (time - 2) - 1) + start;
		};

		var animateScroll = function () {
			var currentDate = +new Date();
			var currentTime = currentDate - startDate;

			doc.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
			if (currentTime < duration) {
				requestAnimationFrame(animateScroll);
			} else {
				doc.scrollTop = to;
			}
		};
		animateScroll();
		enableScroll();
	};

	document.addEventListener("DOMContentLoaded", function () {
		element = document.getElementById('back-to-top');
		window.addEventListener('scroll', trackScroll, false);
		element.addEventListener('click', backToTop, false);

		/*var imboxVisible = false;
		//Check if ImBox is visible
		setInterval(function () {
			var imboxFrame = document.getElementsByClassName("imbox-frame")[1];
			if (imboxFrame == null)
				return;
			var visible = imboxFrame.classList.contains("imbox-show");

			if (visible != imboxVisible) {
				if (visible) {
					var rect = imboxFrame.querySelector(".zoid-outlet").getBoundingClientRect();
					element.style.bottom = (window.innerHeight - rect.top) + "px";
				} else {
					element.style.bottom = "";
				}			
				imboxVisible = visible;
			}
		}, 1000)*/

		trackScroll();
	});
});