window.tryRun("scrollstate", function () {
	'use strict';

	var state = {
		scroll: 0,
		data: {},
    };
    var loadState = {
        scroll: 0,
        data: {},
    };

	if (window.history.state != null)
		loadState = state = window.history.state;

	var updateStateTimer = 0;
	var restoreScrollInterval = 0;
	var enabled = false;
	var triggers = [];
    var triggered = loadState.scroll == 0;

	var restoreScrollCheck = function () {
		triggers.forEach(function (t) {
			if (typeof t.callback === "function" && t.callback() === true)
				document.scrollstate.trigger(t.id);
		});
	}

	window.addEventListener("scroll", function () {
		if (updateStateTimer != 0)
			window.clearTimeout(updateStateTimer);
		updateStateTimer = window.setTimeout(function () {
			updateStateTimer = 0;
			state.scroll = window.scrollY;
			window.history.replaceState(state, "");
		}, 50);
	});

	window.addEventListener("beforeunload", function () {
		state.scroll = window.scrollY;
		window.history.replaceState(state, "");
	});

	document.scrollstate = {
		set: function (name, data) {
			state.data[name] = data;
			window.history.replaceState(state, "");
		},
		get: function (name) {
            if (loadState.data == null || loadState.data[name] == null)
				return null;
            return loadState.data[name];
		},
		register: function (callback) {
			if (!enabled) {
				window.history.scrollRestoration = "manual";
				window.history.replaceState(state, "");
				if (!triggered)
					restoreScrollInterval = window.setInterval(restoreScrollCheck, 10);
				enabled = true;
			}
			if (triggered)
				callback();
			triggers.push({ id: triggers.length, callback: callback, triggered: triggered });
			return triggers.length - 1;
		},
		trigger: function (id) {
			triggers[id].triggered = true;
			var allTriggered = true;
			triggers.forEach(function (t) { allTriggered &= t.triggered; });
			if (!triggered && allTriggered) {
				triggered = true;
				document.scrollstate.triggered = true;
                window.scrollTo(0, loadState.scroll);
				if (restoreScrollInterval != 0)
					window.clearInterval(restoreScrollInterval);
				restoreScrollInterval = 0;
				triggers.forEach(function (t) {
					if (typeof t.callback === "function")
						t.callback();
				});
			}
		},
		triggered: triggered
	};
});