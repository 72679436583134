import './tryrun';

import * as conditioner from 'conditioner-core/conditioner-core';
window.tryRun("conditioner", function () {
	conditioner.addPlugin({
		moduleSetName: name => `${name}.js`,
		moduleGetConstructor: module => module.default,
		moduleImport: name => import(`${name}`)
	});
	conditioner.hydrate(document.documentElement);
});
import './scrollstate';
import './background-blend-mode';
import './matchwidth'
import './matchheight'
import './matchtop';
import './sticky';
import './scrollspy';
import './modal';
import './share';
import './collapse';
import './dropdown';
import './navigation';
import './quicksearch';
import './scrolltotop';
import './form';
import './formFileUpload';
import './reclamationform';
import './cookie-notice';
import './seoimage';
import './google-analytics';
import './buttonfix';
import './notice-message';
import './chatbubble';

window.__litium = window.__litium || {};
const preloadState = window.__litium.preloadState || {};

// iOS Modal Fix
var isMobile = {
	iOS: function () {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i);
	}
}

if (isMobile.iOS())
	document.body.classList.add('apple-ios');