if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
        var el = this;

        do {
            if (Element.prototype.matches.call(el, s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

export default element => {
    var els = element.querySelectorAll('.form__draganddrop');
    var maxEmailSize = 29000000;//element.getAttribute('data-max-file-batch-size').trim();
    var maxFileSize = 10000000;//element.getAttribute('data-max-file-size').trim();
    var totalFiles = [];
    //maxEmailSize = parseInt(maxEmailSize);
    //maxFileSize = parseInt(maxFileSize);
    var totalSize = 0;

    Array.prototype.forEach.call(els, el => {
        var uploadingFiles = 0;
        var fileInput = el.querySelector('input[type="file"]');
        var allFilesInput = el.querySelector('input[type="text"]');
        var compress = el.hasAttribute("data-compress");
        var allFiles = [];
        allFilesInput.value = '';

        var formAddress = '/form/';
        var uploadAddress = formAddress + 'UploadFile';
        var deleteAddress = formAddress + 'DeleteFile';

        var deleteFile;

        var updateGeneralError = function (size) {
            var element = el.querySelector('.form__draganddrop-generalerror');
            var form = el.closest('form');
            var button = form.querySelector('button[type=submit]');

            if (size >= maxEmailSize) {
                element.classList.remove('form__draganddrop-generalerror-hidden');

                if (allFiles.length > 0) {
                    button.disabled = true;
                }
            } else {
                element.classList.add('form__draganddrop-generalerror-hidden');
                button.disabled = false;
            }
        }

        var updateFiles = function () {
            var parent = el.querySelector('.form__draganddrop-files');
            if (allFiles.length > 0)
                allFilesInput.value = JSON.stringify(allFiles);
            else
                allFilesInput.value = '';

            Array.prototype.forEach.call(parent.querySelectorAll('div[data-guid]'), el => {
                var found = false;
                var guid = el.getAttribute('data-guid');
                allFiles.forEach(file => { found |= file.filename === guid; });
                if (!found)
                    parent.removeChild(el);
            });

            allFiles.forEach(file => {
                if (parent.querySelector('div[data-guid="' + file.filename + '"]') !== null)
                    return;

                var div = document.createElement('div');
                div.classList.add('form__draganddrop-file');

                var remove = document.createElement('div');
                remove.classList.add('form__draganddrop-remove');
                remove.addEventListener('click', () => {
                    deleteFile(file);
                });
                div.append(remove);

                div.setAttribute('data-guid', file.filename);
                div.append(file.filename);
                parent.append(div);
            });

            var size = 0;
            allFiles.forEach(file => {
                size += file.size;
            });
            totalSize = size;
            updateGeneralError(size);
        };

        var addUploadError = function (file, messageClass) {
            var parent = el.querySelector('.form__draganddrop-errors');
            var div = document.createElement('div');
            div.classList.add('form__draganddrop-error');
            div.innerText = file.name + ' - ' + fileInput.form.querySelector(messageClass).innerText;
            parent.insertBefore(div, parent.firstChild);
        }

        var clearUploadErrors = function () {
            var parent = el.querySelector('.form__draganddrop-errors');
            while (parent.lastElementChild) {
                parent.removeChild(parent.lastElementChild);
            }
        }

        var uploadFile = function (file, callback) {
            var formData = new FormData();
            formData.set('__RequestVerificationToken', fileInput.form.querySelector('input[name="__RequestVerificationToken"]').value);
            formData.set('file', file);
            formData.set('compress', compress ? "1" : "0");

            var refuseUpload = false;
            var newTotal = 0;

            totalFiles.map((item) => {
                const { size } = item;
                newTotal += size
            })

            const { size } = file;
            newTotal += size;
            totalSize = newTotal;

            if (totalSize >= maxEmailSize && !compress) {
                addUploadError(file, '.form__draganddrop-generalerror-hidden');
                refuseUpload = true;
                el.querySelector('.form__draganddrop-uploading').classList.add('form__draganddrop-uploading-hidden');
            }

            if (size >= maxFileSize && !compress) {
                addUploadError(file, '.form__draganddrop-maxsize')
                refuseUpload = true;
                el.querySelector('.form__draganddrop-uploading').classList.add('form__draganddrop-uploading-hidden');
            }

            if (!refuseUpload) {
                var xhr = new XMLHttpRequest();
                xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            var response = JSON.parse(xhr.responseText);
                            if (response.result === 1) {
                                var currentSize = 0;
                                var uploadedSize = 0;
                                allFiles.map((item) => {
                                    const { size } = item;
                                    currentSize += size;
                                });
                                response.files.map((item) => {
                                    const { size } = item;
                                    uploadedSize += size;
                                });

                                if (currentSize + uploadedSize > maxEmailSize) {
                                    addUploadError(file, '.form__draganddrop-generalerror-hidden');
                                    el.querySelector('.form__draganddrop-uploading').classList.add('form__draganddrop-uploading-hidden');
                                } else {
                                    allFiles.push.apply(allFiles, response.files);
                                    totalFiles.push.apply(totalFiles, response.files);
                                }
                                updateFiles();
                            }
                        } else
                            if (xhr.status === 413) {
                                addUploadError(file, '.form__draganddrop-maxsize');
                            } else {
                                if (xhr.responseText.includes('Maximum request length exceeded')) { //Somtimes returns 500 status when request length is to large
                                    addUploadError(file, '.form__draganddrop-maxsize');
                                } else {
                                    addUploadError(file, '.form__draganddrop-other');
                                }
                            }
                        callback();
                    }
                }
                xhr.open('POST', uploadAddress, true);
                xhr.send(formData);
            }
        }

        var uploadFiles = function (files) {
            if (files.length === 0)
                return;

            if (uploadingFiles === 0)
                el.querySelector('.form__draganddrop-uploading').classList.remove('form__draganddrop-uploading-hidden');
            uploadingFiles++;

            var index = 0;
            clearUploadErrors();
            var callback = function () {
                if (index < files.length) {
                    uploadFile(files[index++], callback);
                } else {
                    uploadingFiles--;
                    if (uploadingFiles === 0) {
                        el.querySelector('.form__draganddrop-uploading').classList.add('form__draganddrop-uploading-hidden');
                    }
                }
            }
            callback();
        }

        deleteFile = function (file) {
            var formData = new FormData();
            formData.set('__RequestVerificationToken', fileInput.form.querySelector('input[name="__RequestVerificationToken"]').value);
            formData.set('FileName', file.filename);
            var xhr = new XMLHttpRequest();
            xhr.open('POST', deleteAddress, true);
            xhr.send(formData);

            var index = -1;
            allFiles.forEach((f, i) => { if (f.filename === file.filename) index = i; });
            if (index !== -1)
                allFiles.splice(index, 1);
            updateFiles();
        }

        fileInput.addEventListener('change', e => {
            uploadFiles(e.target.files);
        });
        el.addEventListener('dragenter', e => {
            e.preventDefault();
            e.stopPropagation();
        });
        el.addEventListener('dragleave', e => {
            e.preventDefault();
            e.stopPropagation();
        });
        el.addEventListener('dragover', e => {
            e.preventDefault();
            e.stopPropagation();
        });
        el.addEventListener('drop', e => {
            e.preventDefault();
            e.stopPropagation();
            uploadFiles(e.dataTransfer.files);
        });
    });
}


function openChildrenGroup(el) {
    console.log(el);
}